<!--药品元数据上传-->
<template>
  <el-container class="layout-container" id="dmU">
    <el-header class="headerClass" style="height: auto;">
      <el-row class="header-row">
        <el-col :span="20">
          <el-form :model="queryParams" ref="queryForm" :inline="true">
            <el-form-item label="上传时间">
              <el-date-picker
                v-model="setDateRange"
                type="daterange"
                value-format="yyyy-MM-dd"
                style="width: 314px"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="timeChange">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="状态" prop="status">
              <el-select
                v-model="queryParams.status"
                placeholder="状态"
                clearable
                size="small"
                style="width: 100px">
                <el-option
                  v-for="dict in statusOptions"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"/>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="small" @click="searchHandle">查询</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" style="text-align: right;">
          <el-button size="small" @click="handleAdd" class="addClass">
            <svg-icon icon-class="upload" style="width: 24px;height: 16px;"/>
            上传药品元数据模版
          </el-button>
        </el-col>
      </el-row>
      <!-- <div class="header-row">
        <div :span="20">
          <el-form :model="queryParams" ref="queryForm" :inline="true">
            <el-form-item label="上传时间">
              <el-date-picker
                v-model="setDateRange"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm"
                style="width: 314px"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                @change="timeChange">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="状态" prop="status">
              <el-select
                v-model="queryParams.status"
                placeholder="状态"
                clearable
                size="small"
                style="width: 100px"
              >
                <el-option
                  v-for="dict in statusOptions"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"/>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="small" @click="searchHandle">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div :span="4">
          <el-button size="small" @click="handleAdd" class="addClass">
            <svg-icon icon-class="upload" style="width: 24px;height: 16px;"/>
            上传药品元数据模版
          </el-button>
        </div>
      </div> -->
    </el-header>
    <el-main class="containerClass card-container">
      <div v-if="cardList.length > 0" class="card-list-box">
        <card-list-component
          ref="cardListDom"
          :cardList="cardList"
          @initData="fetchData"
          @reUpload="reUploadHandle" />
        <!-- <el-row :gutter="20" align="middle">
          <el-col
            v-for="(item, cardIndex) in cardList"
            :key="item.recordId"
            :sm="12" :md="8" :lg="6" :xl="4"
            :class="cardListClass(cardIndex)"
            :style="cardStyle(cardIndex)">
            <el-card class="card-wrap" shadow="hover"> -->
              <!-- <h4 class="status-title">
                <span class="status-circle" :class="circleClassShow(item.status)"></span>
                <span class="status-text">{{ item.statusStr }}</span>
              </h4>
              <div class="total-text">上传总数</div>
              <h3 class="total-number">{{ item.recordCount }}</h3>
              <div class="item">
                <span class="circle"></span>
                <span class="item-label">上传时间：</span>
                <span class="item-text">{{ item.uploadTime }}</span>
              </div>
              <div class="item">
                <span class="circle"></span>
                <span class="item-label">最近操作：</span>
                <span class="item-text">{{ item.latestOperTime }}</span>
              </div>
              <div class="item">
                <span class="circle"></span>
                <span class="item-label">上传方式：</span>
                <span class="item-text item-way">{{ item.uploadType | uploadText}}</span>
              </div>
              <div class="item clearfix">
                <span class="circle fl progress-circle"></span>
                <span class="item-label fl">匹配度：</span>
                <el-progress :percentage="item.matchRatio" color="#2DCD50" class="fl progress-wrap"></el-progress>
              </div> -->
              <!-- <section v-if="Number(item.status) === 1" :class="tagHeader(item.status)">
                <img src="../../../assets//images/institution/chuan.png" />
                <span>已上传</span>
              </section>
              <section v-else-if="Number(item.status) === 2" :class="tagHeader(item.status)">
                <img src="../../../assets//images/institution/tohandle.png" />
                <span>待处理</span>
              </section>
              <section v-else-if="Number(item.status) === 3" :class="tagHeader(item.status)">
                <img src="../../../assets//images/institution/pi.png" />
                <span>待匹配</span>
              </section>
              <section v-else-if="Number(item.status) === 4" :class="tagHeader(item.status)">
                <img src="../../../assets//images/institution/cheng.png" />
                <span>匹配完成</span>
              </section>
              <section class="card-content-section">
                <div :id="'chart' + item.recordId" style="width: 120px;height: 120px"></div>
                <h3 class="total-number">{{ item.recordCount }}</h3>
                <div class="total-text">上传总数</div>
              </section>
              <section class="card-footer-section">
                <div class="item">
                  <span class="circle"></span>
                  <span class="item-label">上传时间：</span>
                  <span class="item-text">{{ item.uploadTime }}</span>
                </div>
                <div class="item">
                  <span class="circle"></span>
                  <span class="item-label">最近操作：</span>
                  <span class="item-text">{{ item.latestOperTime }}</span>
                </div>
                <div class="item">
                  <span class="circle"></span>
                  <span class="item-label">上传方式：</span>
                  <span class="item-text item-way">{{ item.uploadType | uploadText}}</span>
                </div>
                <div v-if="Number(item.status) === 1" class="item-footer">
                  <el-button class="see-btn" @click="matchViewClick(item)">查看</el-button>
                  <el-button class="delete-btn" @click="deleteHandle(item)">删除</el-button>
                </div>
                <div v-else-if="Number(item.status) === 2 || Number(item.status) === 3" class="item-footer">
                  <el-button class="see-btn" @click="matchViewClick(item)">查看</el-button>
                  <el-button class="delete-btn" style="display: none;" @click="cancelHandle(item)">申请撤销</el-button>
                </div>
                <div v-else class="item-footer">
                  <el-button class="see-btn" @click="matchViewClick(item)">查看</el-button>
                </div>
              </section>
            </el-card>
          </el-col>
        </el-row> -->
      </div>
      <el-row v-if="isSearched === false && cardList.length === 0" style="height: 100%;">
        <el-col style="height: 100%;">
          <img src="@/assets/images/no-booking.svg" alt="" style="width: 100%;height: 80%;">
          <p style="text-align: center;">暂无数据</p>
        </el-col>
      </el-row>
      <el-row v-if="isSearched === true && cardList.length === 0" style="height: 100%;">
        <el-col style="height: 100%;">
          <img src="@/assets/images/no-left-data.svg" alt="" style="width: 100%;height: 80%;">
          <p style="text-align: center;">未查询到结果，请修改查询条件重试！</p>
        </el-col>
      </el-row>
    </el-main>
    <file-handle-component
      ref="file-component"
      :record-id="recordId"
      :is-re-uploaded="isReUploaded"
      title="上传药品元数据"
      btn-text="下载药品元数据模板"
      @uploadSucess="uploadSucess" />
  </el-container>
</template>

<script>
import {
  recordCardListApi,
  deleteRecordApi
} from '@/api/dm/drugMetadataUpload/drugMetadataUpload'
import FileHandleComponent from '@/components/FileDownloadAndUpload'
import {
  STATUS_OPTIONS
} from '@/utils/dm/drugMetadataUpload/data'
import CardListComponent from './components/CardList'
export default {
  name: 'DrugMetadataUpload',
  components: {
    FileHandleComponent,
    CardListComponent
  },
  data() {
    return {
      isSearched: false,
      cardList: [
        // {
        //   latestOperTime: '2021-04-06 11:22:51', // 最近操作：
        //   latestProcDataTime: '',
        //   matchRatio: 70, // 匹配度
        //   orgId: '',
        //   recordCount: 1980,
        //   recordId: '1',
        //   status: '1', // 处理状态 1:已上传;2:待匹配;3:待处理;4:配置完成
        //   uploadTime: '2021-04-06 09:38:58', // 上传时间	
        //   uploadType: '1' // 1:WEB;2:API
        // },
        // {
        //   latestOperTime: '2021-04-06 11:22:51', // 最近操作：
        //   latestProcDataTime: '',
        //   matchRatio: 70, // 匹配度
        //   orgId: '',
        //   recordCount: 1980,
        //   recordId: '2',
        //   status: '2', // 处理状态 1:已上传;2:待匹配;3:待处理;4:配置完成
        //   uploadTime: '2021-04-06 09:38:58', // 上传时间	
        //   uploadType: '1' // 1:WEB;2:API
        // },
        // {
        //   latestOperTime: '2021-04-06 11:22:51', // 最近操作：
        //   latestProcDataTime: '',
        //   matchRatio: 70, // 匹配度
        //   orgId: '',
        //   recordCount: 1980,
        //   recordId: '3',
        //   status: '3', // 处理状态 1:已上传;2:待匹配;3:待处理;4:配置完成
        //   uploadTime: '2021-04-06 09:38:58', // 上传时间	
        //   uploadType: '1' // 1:WEB;2:API
        // },
        // {
        //   latestOperTime: '2021-04-06 11:22:51', // 最近操作：
        //   latestProcDataTime: '',
        //   matchRatio: 70, // 匹配度
        //   orgId: '',
        //   recordCount: 1980,
        //   recordId: '4',
        //   status: '4', // 处理状态 1:已上传;2:待匹配;3:待处理;4:配置完成
        //   uploadTime: '2021-04-06 09:38:58', // 上传时间	
        //   uploadType: '1' // 1:WEB;2:API
        // }
      ],
      setDateRange: [], // 创建时间范围
      // 遮罩层
      loading: true,
      // 状态数据字典
      statusOptions: this.$deepClone(STATUS_OPTIONS),
      // 查询参数
      queryParams: {
        startUploadTime: '',
        endUploadTime: '',
        pageNum: 1,
        pageSize: 10000,
        status: ''
      },
      recordId: '',
      isReUploaded: false,
      uploadTemplete: false
    }
  },
  filters: {
    statusText(val) {
      // 1:已上传;2:待处理;3:待匹配;4:配置完成
      if (Number(val) === 1) {
        return '已上传'
      } else if (Number(val) === 2) {
        return '待处理'
      } else if (Number(val) === 3) {
        return '待匹配'
      } else if (Number(val) === 4) {
        return '配置完成'
      }
    },
    uploadText(val) {
      if (Number(val) === 1) {
        return 'WEB'
      } else if (Number(val) === 2) {
        return 'API'
      }
    }
  },
  computed: {
    circleClassShow() {
      return function (val) {
        if (Number(val) === 1) {
          // 已上传
          return 'uploaded'
        } else if (Number(val) === 2) {
          // 待处理
          return 'handle'
        } else if (Number(val) === 3) {
          // 待匹配
          return 'match'
        } else if (Number(val) === 4) {
          // 匹配完成
          return 'finished'
        }
      }
    }
  },
  async created() {
    
    // if (this.$refs.cardListDom) {
    //   await this.$refs.cardListDom.fetchData()
    // }
  },
  async activated() {
    this.recordId = ''
    this.isReUploaded = false
    await this.fetchData()
    // await this.fetchData()
  },
  deactivated() {
    this.$refs.cardListDom && this.$refs.cardListDom.onRemoveListenerMethod()
  },
  mounted() {
    
  },
  methods: {
    async fetchData() {
      let res = await recordCardListApi(this.queryParams)
      if (res.code === 200) {
        this.cardList = res.data.rows || []
        this.cardList.forEach(item => {
          item.matchRatio = item.matchRatio ? item.matchRatio : 0
        })
      }
    },
    timeChange(val) {
      this.setDateRange = val
      this.queryParams.startUploadTime = this.setDateRange ? this.setDateRange[0] : ''
      this.queryParams.endUploadTime = this.setDateRange ? this.setDateRange[1] : ''
    },
    // 上传成功
    async uploadSucess() {
      await this.fetchData()
      // if (this.$refs.cardListDom) {
      //   await this.$refs.cardListDom.fetchData()
      // }
    },
    /** 搜索按钮操作 */
    async searchHandle() {
      this.isSearched = true
      await this.fetchData()
      // if (this.$refs.cardListDom) {
      //   await this.$refs.cardListDom.fetchData()
      // }
    },
    /** 上传药品元数据模板 */
    handleAdd() {
      this.uploadTemplete = true
      this.isReUploaded = false
      if (this.$refs['file-component']) {
        this.$refs['file-component'].uploadTemplete = true
      }
    },
    // 重新上传
    reUploadHandle(item) {
      this.recordId = item.recordId.toString()
      this.isReUploaded = true
      if (this.$refs['file-component']) {
        this.$refs['file-component'].uploadTemplete = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-table .cell .el-form-item {
  margin-bottom: 0px;
}
#dmU {
  width: 100%;
  height: 100%;
  background:#EBEFF7;
}
.headerClass{
  background: #FFFFFF;
  padding: 15px;
  box-shadow: 0 2px 4px 0 rgba(186, 195,  213, 0.60);
  border-radius: 17px;
  .headerTitle{
    margin-top: 5px;
  }
  .header-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
.containerClass{
  background-color: #fff;
  padding: 20px;
  margin-top: 10px;
  border-radius: 17px;
}
.cardContentTitle{
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
}
.cardContent{
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}
.uploadNum{
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #0F49ED;
}
/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateY(36px);
  opacity: 0;
}

</style>
<style lang="scss">
#dmU .el-card__body{
  padding: 14px !important;
}
.card-list-div {
  /* margin-bottom: 10px; */
}
.card-wrap {
  /* background: #EDF3FF;
  border: 1px solid #DBE7FF;
  border-radius: 8px; */
  background: #FFFFFF;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.18);
  border-radius: 20px;
  .tag-header {
    &.uploaded-tag {
      color: #4F4AF3;
    }
    &.match-tag {
      color: #F6A430;
    }
    &.handle-tag {
      color: #E78A75;
    }
    &.finished-tag {
      color: #2DCD50;
    }
    &.recalled-tag {
      color: #FF424C;
    }
    &.rejected-tag {
      color: #FF424C;
    }
    &.stopped-tag {
      color: #FF424C;
    }
    img {
      vertical-align: bottom;
      margin-right: 8px;
    }
  }
  .card-content-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  .card-footer-section {
    .item {
      .circle {
        display: inline-block;
        width: 0;
        height: 0;
      }
      .item-text.item-way {
        background: inherit;
      }
    }
  }
  &:hover {
    background-color: #EDF3FF;
    box-shadow: 0px 0px 18px 0px rgba(35, 80, 204, 0.9);
    color: #111;
    cursor: pointer;
    transition: background-color 0.3s linear;
  }
  &:hover .status-title .status-text,
  &:hover .total-number,
  &:hover .total-text,
  &:hover .item .item-text,
  &:hover .item .item-label,
  &:hover .el-progress__text {
    color: #111;
    transition: background-color 0.3s linear;
  }
  &:hover .total-number {
    text-shadow: 0px 1px 2px rgba(38, 38, 38, 0.9);
    transition: background-color 0.3s linear;
  }
  &:hover .item-footer .el-button.see-btn {
    color: #2B60F8;
    background-color: #fff;
    transition: background-color 0.3s linear;
  }
  &:hover .item-footer .el-button.see-btn {
    color: #2B60F8;
    background-color: #fff;
    transition: background-color 0.3s linear;
  }
  &:hover .item-footer .el-button.delete-btn, &:hover .item-footer .el-button.cancel-btn{
    color: #F52C2C;
    background-color: #fff;
    transition: background-color 0.3s linear;
  }
  .status-title {
    text-align: center;
    .status-circle {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #E4860A;
      border-radius: 5px;
      margin-right: 5px;
      &.uploaded {
        background-color: #51C1E7;
      }
      &.match {
        background-color: #FF9F4D;
      }
      &.handle {
        background-color: #FF5759;
      }
      &.finished {
        background-color: #2DCD50;
      }
    }
    .status-text {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
    }
  }
  .total-text {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
  }
  .total-number {
    /* font-size: 34px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #333333;
    text-shadow: 0px 1px 2px rgba(38, 38, 38, 0.9);
    margin: 15px 0; */
    font-family: SourceHanSansCN-Medium;
    font-size: 40px;
    font-weight: 400;
    color: #000000;
    letter-spacing: 0;
  }
  .item {
    margin-bottom: 10px;
    .circle {
      display: inline-block;
      width: 8px;
      height: 8px;
      background: #2DCD50;
      border-radius: 8px;
      margin-right: 5px;
      &.progress-circle {
        margin-top: 6px;
      }
    }
    .item-label {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
    }
    .item-text {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      &.item-way {
        background: #D8E2FF;
        border-radius: 2px;
        color: #2B60F8;
        padding: 3px;
      }
    }
    .progress-wrap {
      width: calc(100% - 100px);
    }
  }
  .item-footer {
    .el-button {
      padding: 10px 0px !important;
      width: 76px;
      border-radius: 10px;
      &.see-btn {
        background: $topic-color;
        /* box-shadow: 0px 1px 2px 0px rgba(43, 96, 248, 0.9); */
        color: #fff;
        &:hover {
          background: #2B60F8;
          color: #fff;
          transition: background-color 0.3s linear;
        }
      }
      &.delete-btn, &.cancel-btn {
        background: #FFC2C2;
        /* box-shadow: 0px 1px 2px 0px rgba(184, 25, 25, 0.38); */
        color: #F52C2C;
        &:hover {
          background: #FFC2C2;
          color: #F52C2C;
          transition: background-color 0.3s linear;
        }
      }
      &.reUpload-btn {
        background: #fff;
        /* box-shadow: 0px 1px 2px 0px rgba(184, 25, 25, 0.38); */
        color: #4B56F2;
        &:hover {
          background: #4B56F2;
          color: #fff;
          transition: background-color 0.3s linear;
        }
      }
    }
  }
}
</style>
