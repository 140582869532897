var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { height: "100%" } },
    [
      _c(
        "el-container",
        { staticStyle: { height: "100%" } },
        [
          _c(
            "el-aside",
            {
              staticStyle: {
                height: "100%",
                "border-right": "1px solid #DCDFE6",
                "min-width": "300px",
                width: "30%",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    height: "50px",
                    "line-height": "50px",
                    "padding-left": "20px",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { size: "small", placeholder: "请输入内容" },
                    model: {
                      value: _vm.treeSearchVal,
                      callback: function ($$v) {
                        _vm.treeSearchVal = $$v
                      },
                      expression: "treeSearchVal",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        type: "info",
                        size: "mini",
                        icon: "el-icon-refresh",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.doRefresh()
                        },
                      },
                    },
                    [_vm._v("刷新 ")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    height: "calc(100% - 50px)",
                    overflow: "auto",
                    "padding-top": "20px",
                  },
                },
                [
                  _c("el-tree", {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.treeLoading,
                        expression: "treeLoading",
                      },
                    ],
                    ref: "drugTree",
                    attrs: {
                      data: _vm.treeData,
                      props: _vm.defaultTreeProps,
                      "expand-on-click-node": false,
                      "highlight-current": true,
                      "filter-node-method": _vm.filterTreeNode,
                    },
                    on: { "node-click": _vm.handleNodeClick },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var node = ref.node
                          var data = ref.data
                          return _c(
                            "div",
                            { staticClass: "custom-tree-node" },
                            [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: node.level == 1,
                                      expression: "node.level==1",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "el-tag",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: data.batchType == 1,
                                          expression: "data.batchType==1",
                                        },
                                      ],
                                      attrs: { size: "mini" },
                                    },
                                    [_vm._v("国采")]
                                  ),
                                  _c(
                                    "el-tag",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: data.batchType == 2,
                                          expression: "data.batchType==2",
                                        },
                                      ],
                                      attrs: { size: "mini", type: "success" },
                                    },
                                    [_vm._v("联盟")]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        data.docName + " " + data.publishTime
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: node.level == 2,
                                      expression: "node.level==2",
                                    },
                                  ],
                                  style: {
                                    color:
                                      data.selectFlag == 0
                                        ? "#f18181"
                                        : "#606266",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        "[" +
                                          data.orderNo +
                                          "]" +
                                          data.breedName +
                                          " " +
                                          data.spec
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: data.selectFlag == 1,
                                          expression: "data.selectFlag==1",
                                        },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(" (" + data.drugDetailSize + ")")
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-main",
            {
              staticStyle: {
                height: "100%",
                overflow: "hidden",
                padding: "0px",
              },
            },
            [
              _c(
                "el-container",
                { staticStyle: { height: "100%" } },
                [
                  _c(
                    "el-header",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: this.checkNodeData.breedName,
                          expression: "this.checkNodeData.breedName",
                        },
                      ],
                      staticStyle: {
                        height: "auto !important",
                        "min-height": "30px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 20 } }, [
                            _c("span", [_vm._v("关联集采中标药品")]),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "20px" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      this.checkNodeData.breedName +
                                        " " +
                                        this.checkNodeData.spec
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "addClass",
                                  staticStyle: { "margin-left": "20px" },
                                  attrs: {
                                    icon: "el-icon-circle-plus-outline",
                                    size: "mini",
                                  },
                                  on: { click: _vm.addMatDrug },
                                },
                                [_vm._v("新增 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-main",
                    { staticStyle: { padding: "10px" } },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: this.checkNodeData.breedName,
                              expression: "this.checkNodeData.breedName",
                            },
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          ref: "drugDetailTable",
                          attrs: {
                            border: "",
                            stripe: "",
                            "header-cell-style": { "text-align": "center" },
                            "cell-style": { "text-align": "center" },
                            data: _vm.drugDetailTableData,
                            height: "100%",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "index",
                              width: "50",
                              label: "序号",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "150",
                              property: "drugName",
                              label: "药品名称",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              width: "80",
                              property: "formName",
                              label: "剂型",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "150",
                              property: "specDesc",
                              label: "规格包装",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "100",
                              property: "packSpecDesc",
                              label: "规格描述",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              width: "150",
                              property: "manufacturerName",
                              label: "生产企业",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              width: "150",
                              property: "approvalNumList",
                              label: "批准文号",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return _vm._l(
                                    scope.row.approvalNumList,
                                    function (item) {
                                      return _c("p", [_vm._v(_vm._s(item))])
                                    }
                                  )
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              width: "100",
                              property: "priceUnitDesc",
                              label: "计价单位",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              width: "100",
                              property: "price",
                              label: "中选价格",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              width: "150",
                              property: "supplyArea",
                              label: "供应地区",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.transitionSupplyArea(
                                            scope.row.supplyArea
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              width: "150",
                              property: "alternativeSupplyArea",
                              label: "备选供应地区",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.transitionSupplyArea(
                                            scope.row.alternativeSupplyArea
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              fixed: "right",
                              label: "操作",
                              width: "100",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editDrugDetail(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("编辑")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { color: "#F56C6C" },
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.delDrugDetail(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm.drugDetailTableData.length == 0
                            ? _c("template", { slot: "empty" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/no-booking.svg"),
                                    alt: "",
                                  },
                                }),
                                _c("p", [_vm._v("暂无数据")]),
                              ])
                            : _c("template", { slot: "empty" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/no-left-data.svg"),
                                    alt: "",
                                  },
                                }),
                                _c("p", [
                                  _vm._v("未查询到结果，请修改查询条件重试！"),
                                ]),
                              ]),
                        ],
                        2
                      ),
                      !this.checkNodeData.breedName
                        ? [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "250px",
                                  height: "200px",
                                  margin: "0px auto",
                                  "padding-top": "100px",
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/no-booking.svg"),
                                    alt: "",
                                  },
                                }),
                                _c("p", [_vm._v("请先选择一条药品记录")]),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "el-footer",
                    { staticStyle: { height: "40px!important" } },
                    [
                      _c("pagination", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.drugDetailTableDataTotal > 0 &&
                              this.checkNodeData.breedName,
                            expression:
                              "drugDetailTableDataTotal>0 && this.checkNodeData.breedName",
                          },
                        ],
                        attrs: {
                          total: _vm.drugDetailTableDataTotal,
                          page: _vm.drugDetailTableParams.pageNum,
                          limit: _vm.drugDetailTableParams.pageSize,
                          "page-sizes": [10, 20, 50, 100],
                        },
                        on: {
                          "update:page": function ($event) {
                            return _vm.$set(
                              _vm.drugDetailTableParams,
                              "pageNum",
                              $event
                            )
                          },
                          "update:limit": function ($event) {
                            return _vm.$set(
                              _vm.drugDetailTableParams,
                              "pageSize",
                              $event
                            )
                          },
                          pagination: _vm.getRecordDrugDetailList,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.matTableDialogTitle,
            width: "90%",
            "append-to-body": "",
            visible: _vm.matTableDialogVisible,
            "close-on-click-modal": false,
            id: "matTableDialog",
            "before-close": _vm.beforeCloseMatTableDialog,
            top: "1vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.matTableDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "matDrugQueryForm",
                  attrs: {
                    id: "matDrugQueryForm",
                    "status-icon": "",
                    model: _vm.matTableQueryParams,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        staticStyle: { width: "250px", float: "left" },
                        attrs: {
                          placeholder: "请输入药品名称",
                          clearable: "",
                          "show-word-limit": "",
                        },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.queryMatDrugList($event)
                          },
                        },
                        model: {
                          value: _vm.matTableQueryParams.drugName,
                          callback: function ($$v) {
                            _vm.$set(_vm.matTableQueryParams, "drugName", $$v)
                          },
                          expression: "matTableQueryParams.drugName",
                        },
                      }),
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "250px",
                            "margin-left": "20px",
                          },
                          attrs: {
                            filterable: "",
                            remote: "",
                            clearable: "",
                            "reserve-keyword": "",
                            placeholder: "请输入厂家/上市许可持有人名称",
                            "remote-method": _vm.queryManufacturer,
                            loading: _vm.loading,
                          },
                          model: {
                            value: _vm.matTableQueryParams.manufacturerId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.matTableQueryParams,
                                "manufacturerId",
                                $$v
                              )
                            },
                            expression: "matTableQueryParams.manufacturerId",
                          },
                        },
                        _vm._l(_vm.manufacturerList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                      _c("el-input", {
                        staticStyle: { width: "200px", "margin-left": "20px" },
                        attrs: {
                          placeholder: "请输入批准文号",
                          clearable: "",
                          "show-word-limit": "",
                        },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.queryMatDrugList($event)
                          },
                        },
                        model: {
                          value: _vm.matTableQueryParams.approvalNum,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.matTableQueryParams,
                              "approvalNum",
                              $$v
                            )
                          },
                          expression: "matTableQueryParams.approvalNum",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            "margin-left": "20px",
                            background: "#2B60F8",
                          },
                          attrs: {
                            type: "primary",
                            icon: "el-icon-search",
                            size: "small",
                          },
                          on: { click: _vm.queryMatDrugList },
                        },
                        [_vm._v("检索 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "20px" },
                          attrs: {
                            type: "success",
                            icon: "el-icon-finished",
                            size: "small",
                          },
                          on: { click: _vm.batchSelect },
                        },
                        [_vm._v("批量选择 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.matTableLoading,
                      expression: "matTableLoading",
                    },
                  ],
                  ref: "matTable",
                  attrs: {
                    data: _vm.matTableDataList,
                    height: 300,
                    border: "",
                    "header-cell-style": { "text-align": "center" },
                    "cell-style": { "text-align": "center" },
                    stripe: "",
                  },
                  on: { "selection-change": _vm.handleMatTableSelectionChange },
                },
                [
                  _vm.batchSelectButtonGroupShow
                    ? _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          selectable: _vm.rowCanChecked,
                          width: "55",
                        },
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: { type: "index", width: "50", label: "序号" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "100",
                      property: "drugId",
                      label: "药品ID",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "150",
                      property: "drugName",
                      label: "药品名称",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "100",
                      property: "formName",
                      label: "剂型",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "150",
                      property: "specDesc",
                      label: "规格包装",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "100",
                      property: "packSpecDesc",
                      label: "规格描述",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "150",
                      property: "manufacturerName",
                      label: "生产企业",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "150",
                      property: "approvalNumList",
                      label: "批准文号",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return _vm._l(
                            scope.row.approvalNumList,
                            function (item) {
                              return _c("p", [_vm._v(_vm._s(item))])
                            }
                          )
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "150" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            !scope.row.purchaseBatchRecordDrugId &&
                            !_vm.batchSelectButtonGroupShow
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addSelectDrug(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("选择")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.matTableDataTotal > 0 &&
                      !_vm.batchSelectButtonGroupShow,
                    expression:
                      "matTableDataTotal>0 && !batchSelectButtonGroupShow",
                  },
                ],
                attrs: {
                  total: _vm.matTableDataTotal,
                  page: _vm.matTableQueryParams.pageNum,
                  limit: _vm.matTableQueryParams.pageSize,
                  "page-sizes": [10, 20, 50, 100],
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.matTableQueryParams, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.matTableQueryParams, "pageSize", $event)
                  },
                  pagination: _vm.queryMatDrugList,
                },
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.batchSelectButtonGroupShow,
                      expression: "batchSelectButtonGroupShow",
                    },
                  ],
                  staticStyle: {
                    height: "30px",
                    "margin-top": "10px",
                    "text-align": "center",
                  },
                },
                [
                  _c(
                    "el-button-group",
                    { staticStyle: { "margin-right": "20px" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "120px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.batchSelectAll },
                        },
                        [_vm._v("全选")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "120px" },
                          attrs: { type: "success" },
                          on: { click: _vm.batchSelectSubmit },
                        },
                        [_vm._v("确定")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "120px" },
                          attrs: { type: "info" },
                          on: { click: _vm.batchSelectCancel },
                        },
                        [_vm._v("取消")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.matTableChoiceData.length > 0,
                expression: "matTableChoiceData.length>0",
              },
            ],
            staticStyle: { margin: "4px 0" },
          }),
          _c(
            "div",
            { staticStyle: { overflow: "auto", "max-height": "350px" } },
            _vm._l(_vm.matTableChoiceData, function (item, index) {
              return _c(
                "el-card",
                { staticClass: "box-card mat-drug-box-card" },
                [
                  _c("p", { staticClass: "display2row" }, [
                    _c("span", [_vm._v("药品名称:")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(item.drugName))]),
                  ]),
                  _c("p", { staticClass: "display1row" }, [
                    _c("span", [_vm._v("剂型:")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(item.formName))]),
                    _c("span", { staticStyle: { "margin-left": "20px" } }, [
                      _vm._v("规格:"),
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(item.specDesc))]),
                  ]),
                  _c("p", { staticClass: "display1row" }, [
                    _c("span", [_vm._v("生产企业:")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(item.manufacturerName))]),
                  ]),
                  _c("el-divider"),
                  _c("p", [
                    _c("span", [_vm._v("计价单位:")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(item.priceUnit || "__"))]),
                    _c("span", { staticStyle: { "margin-left": "20px" } }, [
                      _vm._v("中选价格:"),
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(item.price || "__"))]),
                  ]),
                  _c("p", { staticClass: "display2row" }, [
                    _c("span", [_vm._v("供应省(区):")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.transitionSupplyArea(item.supplyArea))),
                    ]),
                  ]),
                  _c("p", { staticClass: "display2row" }, [
                    _c("span", [_vm._v("备选供应省(区):")]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.transitionSupplyArea(item.alternativeSupplyArea)
                        )
                      ),
                    ]),
                  ]),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "text-align": "center",
                        position: "relative",
                        top: "6px",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.editDrug(item, index)
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "danger" },
                          on: {
                            click: function ($event) {
                              return _vm.delDrug(index)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.matTableChoiceData.length > 0,
                  expression: "matTableChoiceData.length>0",
                },
              ],
              staticClass: "dialog-footer",
              staticStyle: { "margin-right": "20px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveDrugList } },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑药品属性",
            width: "90%",
            "append-to-body": "",
            visible: _vm.editDrugDialogVisible,
            "close-on-click-modal": false,
            id: "editDrugDialog",
            top: "1vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editDrugDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editDrugForm",
              attrs: {
                "status-icon": "",
                model: _vm.editDrugForm,
                "label-width": "140px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "药品ID:" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.editDrugForm.matId ||
                              _vm.editDrugForm.pdProdMatId
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c("el-form-item", { attrs: { label: "药品名称:" } }, [
                        _vm._v(_vm._s(_vm.editDrugForm.drugName)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "规格:" } }, [
                        _vm._v(_vm._s(_vm.editDrugForm.specDesc)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "剂型:" } }, [
                        _vm._v(_vm._s(_vm.editDrugForm.formName)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "生产企业:" } }, [
                        _vm._v(_vm._s(_vm.editDrugForm.manufacturerName)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "计价单位:",
                            prop: "priceUnitId",
                            rules: [
                              {
                                required: true,
                                message: "请选择计价单位",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: {
                                filterable: "",
                                "filter-method": _vm.packQtyUnitFilter,
                              },
                              on: {
                                focus: function ($event) {
                                  _vm.packQtyUnitDictOptions =
                                    _vm.packQtyUnitDict
                                },
                              },
                              model: {
                                value: _vm.editDrugForm.priceUnitId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editDrugForm, "priceUnitId", $$v)
                                },
                                expression: "editDrugForm.priceUnitId",
                              },
                            },
                            _vm._l(
                              _vm.packQtyUnitDictOptions,
                              function (item, index) {
                                return _c("el-option", {
                                  key:
                                    "specUnit" +
                                    item.conceptId +
                                    item.conceptVal +
                                    index,
                                  attrs: {
                                    label: item.conceptVal,
                                    value: item.conceptId,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "中选价格:",
                            prop: "price",
                            rules: [
                              {
                                required: true,
                                message: "请输入中选价格",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              min: 0,
                              max: 1000000,
                              controls: "",
                              "controls-position": "right",
                              precision: 4,
                            },
                            model: {
                              value: _vm.editDrugForm.price,
                              callback: function ($$v) {
                                _vm.$set(_vm.editDrugForm, "price", $$v)
                              },
                              expression: "editDrugForm.price",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "供应地区:",
                    prop: "supplyArea",
                    rules: [
                      {
                        required: true,
                        message: "请选择供应地区",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _vm.editDrugDialogVisible
                    ? _c("select-supply-area", {
                        attrs: { "area-limit": _vm.checkNodeData.supplyArea },
                        model: {
                          value: _vm.editDrugForm.supplyArea,
                          callback: function ($$v) {
                            _vm.$set(_vm.editDrugForm, "supplyArea", $$v)
                          },
                          expression: "editDrugForm.supplyArea",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "备选供应地区:",
                    prop: "alternativeSupplyArea",
                    rules: [
                      {
                        required: false,
                        message: "请选择备选供应地区",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _vm.editDrugDialogVisible
                    ? _c("select-supply-area", {
                        attrs: { "area-limit": _vm.checkNodeData.supplyArea },
                        model: {
                          value: _vm.editDrugForm.alternativeSupplyArea,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.editDrugForm,
                              "alternativeSupplyArea",
                              $$v
                            )
                          },
                          expression: "editDrugForm.alternativeSupplyArea",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-right": "20px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.editDrugDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitEditDrug },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "批量编辑药品属性",
            width: "90%",
            "append-to-body": "",
            visible: _vm.batchEditDrugDialogVisible,
            top: "1vh",
            "close-on-click-modal": false,
            id: "batchEditDrugDialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.batchEditDrugDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { overflow: "auto" } },
            [
              _c(
                "el-form",
                {
                  ref: "batchEditDrugForm",
                  attrs: {
                    "status-icon": "",
                    model: _vm.batchEditDrugForm,
                    "label-width": "90px",
                  },
                },
                [
                  _vm._l(
                    _vm.batchEditDrugForm.drugList,
                    function (item, index) {
                      return _c(
                        "el-card",
                        {
                          key: item.matId,
                          staticClass: "box-card mat-drug-box-card",
                        },
                        [
                          _c("p", { staticClass: "display2row" }, [
                            _c("span", [_vm._v("药品名称:")]),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(item.drugName))]),
                          ]),
                          _c("p", { staticClass: "display1row" }, [
                            _c("span", [_vm._v("剂型:")]),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(item.formName))]),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "20px" } },
                              [_vm._v("规格:")]
                            ),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(item.specDesc))]),
                          ]),
                          _c("p", { staticClass: "display1row" }, [
                            _c("span", [_vm._v("生产企业:")]),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(item.manufacturerName))]),
                          ]),
                          _c("el-divider"),
                          _c(
                            "p",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "计价单位:",
                                    prop: "drugList." + index + ".priceUnitId",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请选择计价单位",
                                        trigger: "blur",
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "140px" },
                                      attrs: {
                                        filterable: "",
                                        size: "small",
                                        "filter-method": _vm.packQtyUnitFilter,
                                      },
                                      on: {
                                        focus: function ($event) {
                                          _vm.packQtyUnitDictOptions =
                                            _vm.packQtyUnitDict
                                        },
                                      },
                                      model: {
                                        value: item.priceUnitId,
                                        callback: function ($$v) {
                                          _vm.$set(item, "priceUnitId", $$v)
                                        },
                                        expression: "item.priceUnitId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.packQtyUnitDictOptions,
                                      function (t, i) {
                                        return _c("el-option", {
                                          key:
                                            "specUnit" +
                                            t.conceptId +
                                            t.conceptVal +
                                            i,
                                          attrs: {
                                            label: t.conceptVal,
                                            value: t.conceptId,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "中选价格:",
                                    prop: "drugList." + index + ".price",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输入中选价格",
                                        trigger: "blur",
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("el-input-number", {
                                    staticStyle: { width: "140px" },
                                    attrs: {
                                      min: 0,
                                      max: 1000000,
                                      size: "small",
                                      controls: "",
                                      "controls-position": "right",
                                      precision: 4,
                                    },
                                    model: {
                                      value: item.price,
                                      callback: function ($$v) {
                                        _vm.$set(item, "price", $$v)
                                      },
                                      expression: "item.price",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }
                  ),
                  _c("el-row", { staticStyle: { clear: "both" } }),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "供应地区:",
                        prop: "supplyArea",
                        rules: [
                          {
                            required: true,
                            message: "请选择供应地区",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _vm.batchEditDrugDialogVisible
                        ? _c("select-supply-area", {
                            attrs: {
                              "area-limit": _vm.checkNodeData.supplyArea,
                            },
                            model: {
                              value: _vm.batchEditDrugForm.supplyArea,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.batchEditDrugForm,
                                  "supplyArea",
                                  $$v
                                )
                              },
                              expression: "batchEditDrugForm.supplyArea",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "备选供应地区:",
                        prop: "alternativeSupplyArea",
                        rules: [
                          {
                            required: false,
                            message: "请选择备选供应地区",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _vm.batchEditDrugDialogVisible
                        ? _c("select-supply-area", {
                            attrs: {
                              "area-limit": _vm.checkNodeData.supplyArea,
                            },
                            model: {
                              value:
                                _vm.batchEditDrugForm.alternativeSupplyArea,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.batchEditDrugForm,
                                  "alternativeSupplyArea",
                                  $$v
                                )
                              },
                              expression:
                                "batchEditDrugForm.alternativeSupplyArea",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-right": "20px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.batchEditDrugDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitBatchEditDrug },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }