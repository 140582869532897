<template xmlns:el-col="http://www.w3.org/1999/html">
  <div class="app-container" style="height: 100%;">
    <el-container style="height: 100%">
      <el-aside  style="height: 100%;border-right: 1px solid #DCDFE6;min-width: 300px;width: 30%;">
        <div style="height: 50px;line-height: 50px;
        padding-left:20px;">
          <el-input v-model="treeSearchVal" size="small" placeholder="请输入内容" style="width:200px;"></el-input>
          <el-button type="info" size="mini" style="margin-left: 10px;" @click="doRefresh()"
                     icon="el-icon-refresh">刷新
          </el-button>

        </div>
        <div style="height: calc(100% - 50px);overflow: auto;padding-top: 20px;">
          <el-tree :data="treeData" ref="drugTree"
                   :props="defaultTreeProps"
                   v-loading="treeLoading"
                   :expand-on-click-node="false"
                   :highlight-current="true"
                   @node-click="handleNodeClick"
                   :filter-node-method="filterTreeNode">
            <div class="custom-tree-node"
                 slot-scope="{ node, data }"
            >
              <span v-show="node.level==1">
                 <el-tag v-show="data.batchType==1" size="mini">国采</el-tag>
                 <el-tag v-show="data.batchType==2" size="mini" type="success">联盟</el-tag>
                {{ data.docName + ' ' + data.publishTime }}
              </span>
              <span v-show="node.level==2"
                    v-bind:style="{ color: data.selectFlag==0?'#f18181':'#606266'}"
              >
                {{ '['+data.orderNo+']'+ data.breedName + '  ' + data.spec  }}
                <span v-show="data.selectFlag==1">{{'  ('+data.drugDetailSize+')'}}</span>
              </span>
            </div>
          </el-tree>
        </div>
      </el-aside>
      <el-main style="height: 100%;overflow: hidden;padding:0px;">
        <el-container style="height: 100%;">
          <el-header style="height: auto !important;min-height: 30px;"
                     v-show="this.checkNodeData.breedName">
            <el-row>
              <el-col :span="20">
                <span>关联集采中标药品</span>
                <span style="margin-left: 20px;">
            {{ this.checkNodeData.breedName +' '+this.checkNodeData.spec }}
          </span>
              </el-col>
              <el-col :span="4">
                <el-button icon="el-icon-circle-plus-outline" size="mini" style="margin-left: 20px;"
                           class="addClass" @click="addMatDrug">新增
                </el-button>
              </el-col>
            </el-row>
          </el-header>
          <el-main style="padding: 10px;">
            <el-table
              v-show="this.checkNodeData.breedName"
              border
              stripe
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              ref="drugDetailTable"
              :data="drugDetailTableData"
              height="100%"
              v-loading="loading"
            >
              <el-table-column type="index" width="50" label="序号"></el-table-column>
              <el-table-column min-width="150" property="drugName" label="药品名称"></el-table-column>
              <el-table-column width="80" property="formName" label="剂型"></el-table-column>
              <el-table-column min-width="150" property="specDesc" label="规格包装"></el-table-column>
              <el-table-column min-width="100" property="packSpecDesc" label="规格描述"></el-table-column>
              <el-table-column width="150" property="manufacturerName" label="生产企业"></el-table-column>
              <el-table-column width="150" property="approvalNumList" label="批准文号">
                <template slot-scope="scope">
                  <p v-for="item in scope.row.approvalNumList">{{item}}</p>
                </template>
              </el-table-column>
              <el-table-column width="100" property="priceUnitDesc" label="计价单位"></el-table-column>
              <el-table-column width="100" property="price" label="中选价格"></el-table-column>
              <el-table-column width="150" property="supplyArea" label="供应地区">
                <template slot-scope="scope">
                  <span>{{transitionSupplyArea(scope.row.supplyArea)}}</span>
                </template>
              </el-table-column>
              <el-table-column width="150" property="alternativeSupplyArea" label="备选供应地区">
                <template slot-scope="scope">
                  <span>{{transitionSupplyArea(scope.row.alternativeSupplyArea)}}</span>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="100">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="editDrugDetail(scope.row)">编辑</el-button>
                  <el-button type="text" size="small" style="color: #F56C6C;"
                             @click="delDrugDetail(scope.row)" >删除</el-button>
                </template>
              </el-table-column>

              <template slot="empty" v-if="drugDetailTableData.length==0">
                <img src="@/assets/images/no-booking.svg" alt="">
                <p>暂无数据</p>
              </template>
              <template slot="empty" v-else>
                <img src="@/assets/images/no-left-data.svg" alt="">
                <p>未查询到结果，请修改查询条件重试！</p>
              </template>
            </el-table>
            <template  v-if="!this.checkNodeData.breedName">
              <div style="width: 250px;height: 200px;margin: 0px auto;padding-top: 100px;">
                <img src="@/assets/images/no-booking.svg" alt="">
                <p>请先选择一条药品记录</p>
              </div>
            </template>
          </el-main>
          <el-footer style="height: 40px!important;">
            <pagination
              v-show="drugDetailTableDataTotal>0 && this.checkNodeData.breedName"
              :total="drugDetailTableDataTotal"
              :page.sync="drugDetailTableParams.pageNum"
              :limit.sync="drugDetailTableParams.pageSize"
              @pagination="getRecordDrugDetailList"
              :page-sizes="[10, 20, 50, 100]"
            />
          </el-footer>
        </el-container>








      </el-main>

    </el-container>


    <el-dialog  :title='matTableDialogTitle'
               width="90%" append-to-body :visible.sync="matTableDialogVisible"
               :close-on-click-modal="false"  id="matTableDialog"
               :before-close="beforeCloseMatTableDialog"
               top="1vh"
    >
      <div>
        <el-form ref="matDrugQueryForm" id="matDrugQueryForm" status-icon :model="matTableQueryParams">
          <el-form-item>
            <el-input placeholder="请输入药品名称" clearable v-model="matTableQueryParams.drugName"
                      show-word-limit style="width: 250px;float: left"
                      @keydown.enter.native="queryMatDrugList"
            />
            <el-select style="width: 250px;margin-left: 20px;"
                       v-model="matTableQueryParams.manufacturerId"
                       filterable
                       remote
                       clearable
                       reserve-keyword
                       placeholder="请输入厂家/上市许可持有人名称"
                       :remote-method="queryManufacturer"
                       :loading="loading"
            >
              <el-option
                v-for="item in manufacturerList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>

            <el-input placeholder="请输入批准文号" clearable v-model="matTableQueryParams.approvalNum"
                      show-word-limit style="width: 200px;margin-left: 20px;"
                      @keydown.enter.native="queryMatDrugList"
            />
            <el-button type="primary" icon="el-icon-search" @click="queryMatDrugList"
                       style="margin-left: 20px;background: #2B60F8" size="small"
            >检索
            </el-button>

            <el-button type="success" icon="el-icon-finished" @click="batchSelect"
                       style="margin-left: 20px;" size="small"
            >批量选择
            </el-button>
          </el-form-item>
        </el-form>
        <el-table :data="matTableDataList"
                  :height="300" v-loading="matTableLoading"
                  border
                  ref="matTable"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
                  stripe
                  @selection-change="handleMatTableSelectionChange"
        >
          <el-table-column type="selection" :selectable="rowCanChecked" width="55"
                           v-if="batchSelectButtonGroupShow"/>
          <el-table-column type="index" width="50" label="序号"></el-table-column>
          <el-table-column width="100" property="drugId" label="药品ID"></el-table-column>
          <el-table-column min-width="150" property="drugName" label="药品名称"></el-table-column>
          <el-table-column width="100" property="formName" label="剂型"></el-table-column>
          <el-table-column min-width="150" property="specDesc" label="规格包装"></el-table-column>
          <el-table-column min-width="100" property="packSpecDesc" label="规格描述"></el-table-column>
          <el-table-column width="150" property="manufacturerName" label="生产企业"></el-table-column>
          <el-table-column width="150" property="approvalNumList" label="批准文号">
            <template slot-scope="scope">
              <p v-for="item in scope.row.approvalNumList">{{item}}</p>
            </template>
          </el-table-column>
          <el-table-column  label="操作" width="150" >
            <template slot-scope="scope" >
              <el-button type="text" size="small"  @click="addSelectDrug(scope.row)"
                         v-if="!scope.row.purchaseBatchRecordDrugId && !batchSelectButtonGroupShow">选择</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="matTableDataTotal>0 && !batchSelectButtonGroupShow"
          :total="matTableDataTotal"
          :page.sync="matTableQueryParams.pageNum"
          :limit.sync="matTableQueryParams.pageSize"
          @pagination="queryMatDrugList"
          :page-sizes="[10, 20, 50, 100]"
        />
        <div style="height: 30px;margin-top: 10px;text-align: center;" v-show="batchSelectButtonGroupShow">
          <el-button-group style="margin-right: 20px;">
            <el-button style="width: 120px;" type="primary" @click="batchSelectAll">全选</el-button>
            <el-button style="width: 120px;" type="success" @click="batchSelectSubmit">确定</el-button>
            <el-button style="width: 120px;" type="info" @click="batchSelectCancel">取消</el-button>
          </el-button-group>
        </div>

      </div>
      <el-divider style="margin: 4px 0;" v-show="matTableChoiceData.length>0"></el-divider>
      <div style="overflow: auto;max-height: 350px;">
        <el-card class="box-card mat-drug-box-card"
                 v-for="(item,index) in matTableChoiceData">
          <p class="display2row">
            <span>药品名称:</span> <span>{{ item.drugName }}</span>
          </p>
          <p class="display1row">
            <span>剂型:</span> <span>{{ item.formName }}</span>
            <span style="margin-left: 20px;">规格:</span> <span>{{ item.specDesc }}</span>
          </p>
          <p class="display1row">
            <span>生产企业:</span> <span>{{ item.manufacturerName }}</span>
          </p>
          <el-divider></el-divider>
          <p>
            <span>计价单位:</span> <span>{{ item.priceUnit || '__' }}</span>
            <span style="margin-left: 20px;">中选价格:</span> <span>{{ item.price || '__' }}</span>
          </p>
          <p class="display2row">
            <span>供应省(区):</span>
            <span>{{transitionSupplyArea(item.supplyArea)}}</span>
          </p>
          <p class="display2row">
            <span>备选供应省(区):</span>
            <span>{{transitionSupplyArea(item.alternativeSupplyArea)}}</span>
          </p>
          <p style="text-align: center;position:relative;top:6px;">
            <el-button size="mini" type="primary" @click="editDrug(item,index)">编辑</el-button>
            <el-button size="mini" type="danger" @click="delDrug(index)">删除</el-button>
          </p>
        </el-card>
      </div>
      <span slot="footer"  class="dialog-footer" style="margin-right:20px;" v-show="matTableChoiceData.length>0">
        <el-button type="primary" @click="saveDrugList">保存</el-button>
      </span>
    </el-dialog>

    <el-dialog  title="编辑药品属性" width="90%" append-to-body :visible.sync="editDrugDialogVisible"
               :close-on-click-modal="false"  id="editDrugDialog"  top="1vh"
    >
      <el-form ref="editDrugForm" status-icon :model="editDrugForm" label-width="140px">
        <el-row>
          <el-col :span="6">
            <el-form-item label="药品ID:">{{ editDrugForm.matId ||editDrugForm.pdProdMatId }}</el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="药品名称:">{{ editDrugForm.drugName }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="规格:">{{ editDrugForm.specDesc }}</el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="剂型:">{{ editDrugForm.formName }}</el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="生产企业:">{{ editDrugForm.manufacturerName }}</el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="计价单位:" prop="priceUnitId"
                          :rules="[{ required: true, message: '请选择计价单位', trigger: 'blur'}]"
            >
              <el-select v-model="editDrugForm.priceUnitId"
                         style="width: 200px;"
                         filterable
                         :filter-method="packQtyUnitFilter"
                         @focus="packQtyUnitDictOptions=packQtyUnitDict"
              >
                <el-option
                  v-for="(item,index) in packQtyUnitDictOptions"
                  :key="'specUnit'+item.conceptId+item.conceptVal+index"
                  :label="item.conceptVal"
                  :value="item.conceptId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="中选价格:" prop="price"
                          :rules="[{ required: true, message: '请输入中选价格', trigger: 'blur'}]"
            >
              <el-input-number v-model="editDrugForm.price" :min="0" :max="1000000" controls controls-position="right"
                               :precision="4" style="width: 200px;"
              />
            </el-form-item>
          </el-col>
        </el-row>


        <el-form-item label="供应地区:" prop="supplyArea"
                      :rules="[{ required: true, message: '请选择供应地区', trigger: 'blur'}]"
        >
          <select-supply-area v-model="editDrugForm.supplyArea" v-if="editDrugDialogVisible"
                              :area-limit="checkNodeData.supplyArea"></select-supply-area>

        </el-form-item>

        <el-form-item label="备选供应地区:" prop="alternativeSupplyArea"
                      :rules="[{ required: false, message: '请选择备选供应地区', trigger: 'blur'}]"
        >
          <select-supply-area v-model="editDrugForm.alternativeSupplyArea" v-if="editDrugDialogVisible"
                              :area-limit="checkNodeData.supplyArea"></select-supply-area>

        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" style="margin-right:20px;">
        <el-button @click="editDrugDialogVisible=false">取 消</el-button>
        <el-button type="primary" @click="submitEditDrug">确 定</el-button>
      </span>
    </el-dialog>


    <el-dialog  title="批量编辑药品属性" width="90%" append-to-body
               :visible.sync="batchEditDrugDialogVisible"  top="1vh"
               :close-on-click-modal="false"  id="batchEditDrugDialog"
    >
      <div style="overflow: auto;">
        <el-form ref="batchEditDrugForm" status-icon :model="batchEditDrugForm"
                 label-width="90px">
          <el-card class="box-card mat-drug-box-card"
                   :key="item.matId"
                   v-for="(item,index) in batchEditDrugForm.drugList">
            <p class="display2row">
              <span>药品名称:</span> <span>{{ item.drugName }}</span>
            </p>
            <p class="display1row">
              <span>剂型:</span> <span>{{ item.formName }}</span>
              <span style="margin-left: 20px;">规格:</span> <span>{{ item.specDesc }}</span>
            </p>
            <p class="display1row">
              <span>生产企业:</span> <span>{{ item.manufacturerName }}</span>
            </p>
            <el-divider></el-divider>
            <p>
              <el-form-item label="计价单位:"
                            :prop="'drugList.' + index + '.priceUnitId'"
                            :rules="[{ required: true, message: '请选择计价单位', trigger: 'blur'}]"
              >
                <el-select v-model="item.priceUnitId"
                           style="width: 140px;"
                           filterable
                           size="small"
                           :filter-method="packQtyUnitFilter"
                           @focus="packQtyUnitDictOptions=packQtyUnitDict"
                >
                  <el-option
                    v-for="(t,i) in packQtyUnitDictOptions"
                    :key="'specUnit'+t.conceptId+t.conceptVal+i"
                    :label="t.conceptVal"
                    :value="t.conceptId"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="中选价格:"
                            :prop="'drugList.' + index + '.price'"
                            :rules="[{ required: true, message: '请输入中选价格', trigger: 'blur'}]"
              >
                <el-input-number v-model="item.price" :min="0" :max="1000000" size="small"
                                 controls controls-position="right"
                                 :precision="4" style="width: 140px;"
                />
              </el-form-item>
            </p>
          </el-card>
          <el-row style="clear:both"></el-row>
          <el-form-item label="供应地区:" prop="supplyArea"
                        :rules="[{ required: true, message: '请选择供应地区', trigger: 'blur'}]"
          >
            <select-supply-area v-model="batchEditDrugForm.supplyArea" v-if="batchEditDrugDialogVisible"
                                :area-limit="checkNodeData.supplyArea"></select-supply-area>
          </el-form-item>

          <el-form-item label="备选供应地区:" prop="alternativeSupplyArea"
                        :rules="[{ required: false, message: '请选择备选供应地区', trigger: 'blur'}]"
          >
            <select-supply-area v-model="batchEditDrugForm.alternativeSupplyArea" v-if="batchEditDrugDialogVisible"
                                :area-limit="checkNodeData.supplyArea"></select-supply-area>
          </el-form-item>

        </el-form>

      </div>
      <span slot="footer" class="dialog-footer" style="margin-right:20px;">
        <el-button @click="batchEditDrugDialogVisible=false">取 消</el-button>
        <el-button type="primary" @click="submitBatchEditDrug">确 定</el-button>
      </span>
    </el-dialog>
  </div>


</template>

<script>
import conceptConst from '@/const/conceptConst'
import {getConcept} from '@/utils/concept'
import {recordDrugTree, getDrugDetailList, addDrugDetailList,delDrugDetail,updateDrugDetail} from '@/api/dkm/purchaseBatchRecord'
import {isEmpty} from '@/utils/common'
import {selectPdProdMatDetailList} from '@/api/dkm/pkgDrugProd'
import {deepClone} from "../../../utils";
import { selectManufacturerNameByKey } from '@/api/dkm/manufacturer'
import selectSupplyArea from '@/views/dkm/purchaseBatch/components/selectSupplyArea'

export default {
  name: 'TreeList',
  components: {selectSupplyArea },
  data() {
    return {
      conceptConst,
      // 遮罩层
      loading: false,
      treeLoading:false,
      treeSearchVal: '',
      treeData: [],
      defaultTreeProps: {
        children: 'drugList',
        label: 'docName'
      },
      //选中的药品树节点
      checkNodeData: {},
      drugDetailTableData: [],
      drugDetailTableDataTotal: 0,
      drugDetailTableParams: {
        pageNum: 1,
        pageSize: 10,
        orderByColumn: undefined,
        isAsc: undefined,
        drugId: undefined,
      },
      matTableData: [],
      //包装材料药品表格数据
      matTableDataList: [],
      // 包装材料药品表格总条数
      matTableDataTotal: 0,
      matTableLoading:false,
      // 包装材料药品表格高度
      matTableHeight: window.innerHeight - 100 - 100,
      matTableDialogVisible: false,
      manufacturerList:[],
      matTableQueryParams: {
        pageNum: 1,
        pageSize: 10,
        queryType:1,
        drugName: "",
        approvalNum:"",
        purchaseBatchRecordDrugId:"",
        manufacturerId: "",
        orderByColumn: undefined,
        isAsc: undefined
      },
      // 选择的数据
      matTableChoiceData: [],
      //编辑药品属性弹窗
      editDrugDialogVisible: false,
      editDrugForm: {},
      supplyAreaStr:"",
      /*包装数量单位字典*/
      packQtyUnitDict: [],
      packQtyUnitDictOptions: [],
      areaDict: [
        {orderNo: "A", area: [{code:"340000",name:"安徽"}, {code:"820000",name:"澳门"}]},
        {orderNo: "B", area: [{code:"110000",name:"北京"}]},
        {orderNo: "C", area: [{code:"500000",name:"重庆"}, {code:"510100",name:"成都"}]},
        {orderNo: "D", area: [{code:"210200",name:"大连"}]},
        {orderNo: "F", area: [{code:"350000",name:"福建"}]},
        {orderNo: "G", area: [{code:"440000",name:"广东"},{code:"620000",name:"甘肃"} , {code:"520000",name:"贵州"},{code:"450000",name:"广西"},{code:"440100",name:"广州"}]},
        {orderNo: "H", area: [{code:"430000",name:"湖南"}, {code:"420000",name:"湖北"}, {code:"230000",name:"黑龙江"}, {code:"130000",name:"河北"}, {code:"410000",name:"河南"}, {code:"460000",name:"海南"}]},
        {orderNo: "J", area: [{code:"320000",name:"江苏"}, {code:"220000",name:"吉林"}, {code:"360000",name:"江西"}]},
        {orderNo: "L", area: [{code:"210000",name:"辽宁"}]},
        {orderNo: "N", area: [{code:"150000",name:"内蒙古"}, {code:"640000",name:"宁夏"}]},
        {orderNo: "Q", area: [{code:"630000",name:"青海"}]},
        {orderNo: "S", area: [{code:"310000",name:"上海"},{code:"370000",name:"山东"} , {code:"610000",name:"陕西"}, {code:"140000",name:"山西"}, {code:"510000",name:"四川"},{code:"210100",name:"沈阳"},{code:"440300",name:"深圳"}]},
        {orderNo: "T", area: [{code:"120000",name:"天津"}, {code:"710000",name:"台湾"}]},
        {orderNo: "X", area: [{code:"650000",name:"新疆"},{code:"650001",name:"新疆生产建设兵团"}, {code:"540000",name:"西藏"},{code:"810000",name:"香港"} ,{code:"350200",name:"厦门"} ,{code:"610100",name:"西安"}]},
        {orderNo: "Y", area: [{code:"530000",name:"云南"}]},
        {orderNo: "Z", area: [{code:"330000",name:"浙江"}]},
      ],
      areaMap:{},
      batchSelectButtonGroupShow:false,
      //批量选中的数据
      matTableBatchSelectData:[],
      batchEditDrugDialogVisible:false,
      batchEditDrugForm:{
        drugList:[],
        supplyArea:'',
        alternativeSupplyArea:''
      }


    }
  },
  watch: {
    // 监听输入框的值变化
    treeSearchVal(val) {
      this.treeLoading=true
      this.$refs.drugTree.filter(val);
      this.treeLoading=false
    }
  },
  computed: {
    matTableDialogTitle:function (){
      let title='新增关联集中采购药品'
      if(this.checkNodeData){
        title+='    ---'+this.checkNodeData.breedName +' '+this.checkNodeData.spec
      }
      return title
    }

  },
  created() {

    // 地区 代码 和名称映射关系
    this.areaMap=new Map()
    let areaMap=this.areaMap
    this.areaDict.forEach((item, index) => {
      item.area.forEach(t=>{
        areaMap.set(t.code,t.name)
      })
    })
    this.geTreeData()

    /**规格包装单位概念域（片、粒、袋、支、瓶、盒等）*/
    getConcept(conceptConst.PUB_SPEC_PKG_UNIT, 0).then(result => {
      this.packQtyUnitDict = result
      this.packQtyUnitDictOptions = result
    })




  },
  activated() {
  },
  mounted() {

  },
  methods: {
    //点击刷新按钮
    doRefresh() {
      this.treeSearchVal = ''
      this.checkNodeData.breedName=''
      this.geTreeData()
    },

    geTreeData() {
      this.treeLoading=true
      recordDrugTree().then(res => {
        this.treeData = res.data
        this.treeLoading=false
      })
    },
    /* 左侧树节点点击*/
    handleNodeClick(data, node) {
      if(data.breedName){
        if(data.selectFlag==0){
          this.checkNodeData={}
          this.msgInfo("未中标药品，无需维护")
        }else{
          this.checkNodeData = data
          this.getRecordDrugDetailList(1)
        }
      }else{
        this.checkNodeData=data
      }
    },
    //树节点过滤
    filterTreeNode(value, data) {
      if (isEmpty(value)) {
        return true;
      }
      return data.breedName && data.breedName.indexOf(value.trim()) !== -1
    },
    //点击添加包装材料药品
    addMatDrug() {
      this.matTableChoiceData=[]
      this.matTableDialogVisible = true
      this.matTableQueryParams.drugName=''
      this.matTableQueryParams.manufacturerId=''
      this.matTableQueryParams.approvalNum=''
      this.manufacturerList=[]
      if(this.drugDetailTableData.length>0){
        let firstItem=this.drugDetailTableData[0]
        this.matTableQueryParams.drugName=firstItem.drugName
        if(this.drugDetailTableData.length==1){
          if(firstItem.manufacturerList){
            let manufacturer=firstItem.manufacturerList[0]
            this.manufacturerList.push({id:manufacturer.id,name:manufacturer.name})
            this.matTableQueryParams.manufacturerId=manufacturer.id
          }
        }
      }
      this.queryMatDrugList()
      this.batchSelectButtonGroupShow=false
    },
    //关闭包装材料药品添加弹窗之前
    beforeCloseMatTableDialog(done){

      if(this.matTableChoiceData.length>0){
        this.$confirm('数据未保存，确认关闭?')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      }else{
        done()
      }

    },
    //根据招采批次药品id获取关联的系统包装材料药品明细
    getRecordDrugDetailList(type) {
      this.loading=true
      let form = this.drugDetailTableParams
      form.drugId = this.checkNodeData.id
      if (type == 1) {
        //重新执行查询，从第一页开始
        form.pageNum = 1
      }
      getDrugDetailList(form).then(res => {

        res.rows.forEach(row => {
          if(row.pdProdMatBean){
            this.handlePdProdMatModel(row.pdProdMatBean)
            row.drugName=row.pdProdMatBean.drugName
            row.formName=row.pdProdMatBean.formName
            row.specDesc=row.pdProdMatBean.specDesc
            row.packSpecDesc=row.pdProdMatBean.packSpecDesc
            row.manufacturerName=row.pdProdMatBean.manufacturerName
            row.approvalNumList=row.pdProdMatBean.approvalNumList
            row.manufacturerList=row.pdProdMatBean.manufacturerList
          }

        })

        this.drugDetailTableData = res.rows
        this.drugDetailTableDataTotal = res.total
        this.loading=false
      })

    },
    //删除药品
    delDrugDetail(row){
      let _this = this
      this.$confirm('确认要删除' + '[' + row.drugName + ']'+'"吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        delDrugDetail(row.id).then(response => {
          _this.getRecordDrugDetailList()
          _this.msgSuccess('操作成功')
        })
      })
    },
    //编辑药品
    editDrugDetail(row){
      this.editDrugDialogVisible = true
      this.supplyAreaStr=""
      this.editDrugForm = deepClone(row)
      // 从表格点击编辑 editType=1
      this.editDrugForm.editType =1


    },
    //转换包装材料数据
    handlePdProdMatModel(row){
      // 组合规格数据
      let specDesc = ''
      let showSpecDesc=[]
      row.dkbPpSpecList.forEach(specItem=>{
        showSpecDesc.push(specItem.showSpecDesc)
      })
      specDesc += showSpecDesc.toString() + " * "


      let dkbPdSpec=row.dkbPdSpec
      if(dkbPdSpec){
        //有包装拆分明细
        specDesc += dkbPdSpec.level1PackQty + row.specUnitDesc +'/'+dkbPdSpec.level1PackQtyUnitDesc
          + '*' +dkbPdSpec.level2PackQty + dkbPdSpec.level1PackQtyUnitDesc +'/'+ dkbPdSpec.level2PackQtyUnitDesc
        specDesc +=  "(" + row.wrapperDesc + ")"
      }else{
        //无包装拆分明细
        specDesc += row.packQty + row.specUnitDesc
        specDesc += " / " + row.packQtyUnitDesc + "(" + row.wrapperDesc + ")"
      }

      row.specDesc = specDesc

      //组合剂型（因为存在组合包装药品，一个包装2种药品）
      let formSet = new Set()
      row.formList.forEach(formItem =>{
        formSet.add(formItem.name)
      })
      row.formName = Array.from(formSet).toString()
      //组合生产企业（因为存在组合包装药品，一个包装2种药品）
      let manufacturerSet = new Set()
      row.manufacturerList.forEach(manufacturerItem =>{
        manufacturerSet.add(manufacturerItem.name)
      })
      row.mahList.forEach(manufacturerItem =>{
        manufacturerSet.add(manufacturerItem.name)
      })
      row.manufacturerName = Array.from(manufacturerSet).toString()

      //组合药品id数据 普通包装（药品id+包装药品id+包装材料id） 组合包装（组合包装药品id+包装材料id）
      if(row.packType==1){
        row.drugId=row.drugProdId+'-'+row.pkgDrugProdId+'-'+row.matId
      }else{
        row.drugId=row.pkgDrugProdId+'-'+row.matId
      }

    },
    //批量选择
    batchSelect(){
      //清除之前多选内容
      this.$refs.matTable.clearSelection();
      this.batchSelectButtonGroupShow=true
      this.$nextTick(() => {
        this.$refs.matTable.doLayout()
      })

    },
    queryMatDrugList() {
      this.matTableLoading=true
      //隐藏批量选择的操作栏
      this.batchSelectButtonGroupShow=false
      this.matTableQueryParams.purchaseBatchRecordDrugId=this.checkNodeData.id
      selectPdProdMatDetailList(this.matTableQueryParams).then(res => {
        res.rows.forEach(row => {
          this.handlePdProdMatModel(row)

        })
        this.matTableDataList = res.rows
        this.matTableDataTotal = res.total
        //解决表格固定列-滚动时候错位
        setTimeout(() => {
          this.$nextTick(() => {
            this.$refs.matTable.doLayout()
          })
        }, 300)
        this.matTableLoading=false
      })
    },
    //包装材料表格多选框值变化
    handleMatTableSelectionChange(val) {
      this.matTableBatchSelectData = val
    },
    //包装材料表格-选中一行
    addSelectDrug(row){
      let exist=false
      this.matTableChoiceData.some(item=>{
        if(item.matId==row.matId){
          exist=true
          return true
        }
      })
      if(!exist){
        //this.matTableChoiceData.push(row)
        let index=-1
        this.editDrug(row,index)
      }else{
        this.msgInfo("请勿重复添加")
      }

    },
    editDrug(data, index) {
      this.resetForm("editDrugForm");
      this.supplyAreaStr=""
      this.editDrugDialogVisible = true
      this.editDrugForm = deepClone(data)
      this.editDrugForm.index = index

      if(!this.editDrugForm.supplyArea && this.checkNodeData.supplyArea){
        this.$set(this.editDrugForm, "supplyArea", this.checkNodeData.supplyArea)
      }
      if(!this.editDrugForm.priceUnitId){
        this.$set(this.editDrugForm, "priceUnitId", conceptConst.PUB_SPEC_PKG_UNIT_BOX)
      }

    },
    //新增药品弹窗--删除药品
    delDrug(index) {
      this.matTableChoiceData.splice(index,1)
    },
    //批量编辑--确定按钮
    submitBatchEditDrug(){
      this.$refs['batchEditDrugForm'].validate(valid => {
        if (valid) {
          let drugId = this.checkNodeData.id
          this.batchEditDrugForm.drugList.forEach(item => {
            item.drugId = drugId
            item.pdProdMatId = item.matId
            item.supplyArea = this.batchEditDrugForm.supplyArea
            item.alternativeSupplyArea = this.batchEditDrugForm.alternativeSupplyArea
          })
          addDrugDetailList(this.batchEditDrugForm.drugList).then(res=>{
            this.msgSuccess()
            this.batchEditDrugDialogVisible=false
            this.getRecordDrugDetailList()
            //刷新包装药品表格
            this.queryMatDrugList()
          })

        } else {
          this.msgError('请规范填写')
        }
      })
    },
    submitEditDrug() {
      let index = this.editDrugForm.index
      let editType =this.editDrugForm.editType
      let form = this.editDrugForm
      this.$refs['editDrugForm'].validate(valid => {
        if (valid) {

          if(editType==1){
            //从表格的编辑打开后保存数据
            updateDrugDetail(this.editDrugForm).then(res=>{
              this.msgSuccess()
              this.getRecordDrugDetailList()
            })
          }else{
            //处理字典项-规格单位
            let form = this.editDrugForm
            form.priceUnit = this.getConceptVal(this.packQtyUnitDict, form.priceUnitId)
            if(index==-1){
              //新增
              this.matTableChoiceData.push(deepClone(this.editDrugForm))
            }else{
              //编辑
              this.matTableChoiceData[index] = deepClone(this.editDrugForm)
            }
          }

          this.editDrugDialogVisible = false
          /*editRecord(this.recordEditFormData).then(res => {
            this.msgSuccess(res.msg)
            this.recordEditDialogVisible = false
            this.handleQuery(1)
          })*/
        } else {
          this.msgError('请规范填写')
        }
      })
    },
    //保存药品列表
    saveDrugList() {
      let drugId = this.checkNodeData.id
      this.matTableChoiceData.forEach(item => {
        item.drugId = drugId
        item.pdProdMatId = item.matId
      })
      addDrugDetailList(this.matTableChoiceData).then(res => {
        this.matTableChoiceData=[]
        //刷新包装药品表格
        this.queryMatDrugList()
        this.msgSuccess()
        this.getRecordDrugDetailList()
      })
    },
    /* 包装数量单位--下拉搜索过滤*/
    packQtyUnitFilter(query) {
      if (query !== '') {
        this.packQtyUnitDictOptions = this.packQtyUnitDict.filter(item => {
          let flag = item.conceptVal.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.spell.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.wubi.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
          //概念别名过滤
          if (item.aliasList) {
            item.aliasList.some(aliasItem => {
              if ((aliasItem.conceptName && aliasItem.conceptName.toLowerCase().indexOf(query.toLowerCase()) > -1)
                || (aliasItem.spell && aliasItem.spell.toLowerCase().indexOf(query.toLowerCase()) > -1)
                || (aliasItem.wubi && aliasItem.wubi.toLowerCase().indexOf(query.toLowerCase()) > -1)
              ) {
                flag = true
                return true
              }
            })
          }
          return flag
        })

      } else {
        this.packQtyUnitDictOptions = this.packQtyUnitDict
      }
    },
    /*根据conceptId从字典中获取概念值的描述*/
    getConceptVal(dict, conceptId) {
      let conceptVal
      dict.some(item => {
        if (item.conceptId === conceptId) {
          conceptVal = item.conceptVal
          return true
        }
      })
      return conceptVal
    },
    /*判断新增关联集中采购药品列表--表格行的多选框是否可以选择*/
    rowCanChecked(row, index){
      if(row.purchaseBatchRecordDrugId){
        return false
      }else{
        return true
      }
    },
    //包装材料药品表格-全选
    batchSelectAll(){
      this.$refs.matTable.toggleAllSelection();
    },
    //包装材料药品表格- 批量选择--确定按钮
    batchSelectSubmit(){
      if(this.matTableBatchSelectData.length==0){
        this.msgInfo("请勾选需要编辑的药品")
        return
      }
      this.resetForm("batchEditDrugForm");
      this.supplyAreaStr=""
      this.batchSelectButtonGroupShow=false
      this.batchEditDrugDialogVisible=true
      this.batchEditDrugForm.drugList=[]
      this.batchEditDrugForm.drugList.push(...this.matTableBatchSelectData)
      this.batchEditDrugForm.supplyArea=""
      if(this.checkNodeData.supplyArea){
        this.$set( this.batchEditDrugForm, "supplyArea",this.checkNodeData.supplyArea)
      }
      this.batchEditDrugForm.alternativeSupplyArea=""
      this.batchEditDrugForm.drugList.forEach((item,index)=>{
        this.$set( this.batchEditDrugForm.drugList[index], "priceUnitId",conceptConst.PUB_SPEC_PKG_UNIT_BOX)
      })
      this.$forceUpdate()

    },
    //批量选择--取消
    batchSelectCancel(){
      this.batchSelectButtonGroupShow=false
      this.$refs.matTable.clearSelection();
    },

    /*远程搜索厂商信息*/
    async queryManufacturer(query) {
      if (!isEmpty(query)) {
        let res = await selectManufacturerNameByKey(query.trim())
        if (res.code === 200) {
          this.manufacturerList = res.data
        }
      } else {
        this.manufacturerList = []
      }
    },
    // 将区域代码 转换为名称
    transitionSupplyArea(supplyArea){
      if(isEmpty(supplyArea)){
        return ""
      }
      let areaCodeList=supplyArea.split(",")
      let areaNameList=[]
      areaCodeList.forEach(code=>{
        areaNameList.push(this.areaMap.get(code))
      })
      return areaNameList.toString()
    }

  }
}
</script>

<style lang="scss" scoped>
.custom-tree-node {
  font-size: 14px;
}

.box-card {
  width: 350px;
  float: left;
  height: 220px;
  margin-bottom: 10px;
}

.display1row {
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.display2row {
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.display3row {
  -webkit-line-clamp: 3;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
aside {
  background: #fdfdfd;
  padding: 0px 0px;
}

</style>

<style>
#matTableDialog .el-dialog__body {
  padding: 10px 20px;
}

#matDrugQueryForm .el-form-item {
  margin-bottom: 10px;
}

.mat-drug-box-card {
  margin-right: 10px;
}

.mat-drug-box-card .el-divider {
  margin: 8px 0;
}

#batchEditDrugDialog .el-form-item {
  margin-bottom: 14px;
}
</style>
